@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


a{
    text-decoration: none;
}


/* slider section  */

.slider{
    height: 100vh;
    margin-top: -50px;
    width: 100vw;
    overflow: hidden;
    position: relative;
}
.slider .list .item{
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0 0 0 0;
}
.slider .list .item img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.slider .list .item .content{
    position: absolute;
    top: 20%;
    width: 1140px;
    max-width: 80%;
    left: 50%;
    transform: translateX(-50%);
    padding-right: 30%;
    box-sizing: border-box;
    color: #fff;
    text-shadow: 0 5px 10px #0004;
}

.slider .list .item .content .title,
.slider .list .item .content .type{
    font-size: 4rem;
    font-weight: semi-bold;
    line-height: 1.1em;
}

@media (max-width: 1025px) {
    .slider .list .item .content .title,
    .slider .list .item .content .type {
        font-size: 2.8rem; /* Adjust font size for screens <= 1025px */
        line-height: 1.2em; /* Add slight spacing for better readability */
    }
}

.slider .list .item .type{
    color: #14ff72cb;
}
.slider .list .item .button{
    display: grid;
    grid-template-columns: repeat(2, 130px);
    grid-template-rows: 40px;
    gap: 5px;
    margin-top: 20px;
}
.slider .list .item .button button{
    border: none;
    background-color: #eee;
    font-family: Poppins;
    font-weight: 500;
    cursor: pointer;
    transition: 0.4s;
    letter-spacing: 2px;
}


.slider .list .item .button button:hover{
    letter-spacing: 3px;
}
.slider .list .item .button button:nth-child(2){
    background-color: transparent;
    border: 1px solid #fff;
    color: #eee;
}





/* Thumbnail Section  */
.thumbnail{
    position: absolute;
    bottom: 50px;
    left: 50%;
    width: max-content;
    z-index: 100;
    display: flex;
    gap: 20px;
}

.thumbnail .item{
    width: 150px;
    height: 220px;
    flex-shrink: 0;
    position: relative;
}

.thumbnail .item img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 5px 0 15px rgba(0, 0, 0, 0.3);
}



.content > .description {
    width: 79%;
    font-size: 19px; /* Default size for large screens */
    line-height: 1.5em;
    text-align: justify;
    margin-bottom: 1.5em;

    /* Responsive styles */
    @media (max-width: 1024px) { /* Tablets */
        font-size: 17px;
        width: 85%;
    }

    @media (max-width: 768px) { /* Large mobile devices */
        font-size: 16px;
        width: 90%;
    }

    @media (max-width: 480px) { /* Small mobile devices */
        font-size: 14px;
        width: 95%;
    }
}

/* nextPrevArrows Section  */
.nextPrevArrows{
    position: absolute;
    top: 80%;
    right: 52%;
    z-index: 100;
    width: 300px;
    max-width: 30%;
    display: flex;
    gap: 10px;
    align-items: center;
}
.nextPrevArrows button{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: white;
    border: none;
    color: #fff;
    font-family: monospace;
    font-weight: bold;
    transition: .5s;
    cursor: pointer;
}
.nextPrevArrows button:hover{
    background-color: #fff;
    color: #000;
}

/* Animation Part */
.slider .list .item:nth-child(1){
    z-index: 1;
}


/* animation text in first item */
.slider .list .item:nth-child(1) .content .title,
.slider .list .item:nth-child(1) .content .type,
.slider .list .item:nth-child(1) .content .description,
.slider .list .item:nth-child(1) .content .buttons
{
    transform: translateY(50px);
    filter: blur(20px);
    opacity: 0;
    animation: showContent .5s 1s linear 1 forwards;
}
@keyframes showContent{
    to{
        transform: translateY(0px);
        filter: blur(0px);
        opacity: 1;
    }
}
.slider .list .item:nth-child(1) .content .title{
    animation-delay: 0.4s !important;
}
.slider .list .item:nth-child(1) .content .type{
    animation-delay: 0.6s !important;
}
.slider .list .item:nth-child(1) .content .description{
    animation-delay: 0.8s !important;
}
.slider .list .item:nth-child(1) .content .buttons{
    animation-delay: 1s !important;
}




/* Animation for next button click */
.slider.next .list .item:nth-child(1) img{
    width: 150px;
    height: 220px;
    position: absolute;
    bottom: 50px;
    left: 50%;
    border-radius: 30px;
    animation: showImage .5s linear 1 forwards;
}

@keyframes showImage{
    to{
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 0;
    }
}

.slider.next .thumbnail .item:nth-last-child(1){
    overflow: hidden;
    animation: showThumbnail .5s linear 1 forwards;
}
.slider.prev .list .item img{
    z-index: 100;
}


@keyframes showThumbnail{
    from{
        width: 0;
        opacity: 0;
    }
}


.slider.next .thumbnail{
    animation: effectNext .5s linear 1 forwards;
}

@keyframes effectNext{
    from{
        transform: translateX(150px);
    }
}



/* Animation for prev button click */
.slider.prev .list .item:nth-child(2){
    z-index: 2;
}

.slider.prev .list .item:nth-child(2) img{
    animation: outFrame 0.5s linear 1 forwards;
    position: absolute;
    bottom: 0;
    left: 0;
}
@keyframes outFrame{
    to{
        width: 150px;
        height: 220px;
        bottom: 50px;
        left: 50%;
        border-radius: 20px;
    }
}

.slider.prev .thumbnail .item:nth-child(1){
    overflow: hidden;
    opacity: 0;
    animation: showThumbnail .5s linear 1 forwards;
}
.slider.next .nextPrevArrows button,
.slider.prev .nextPrevArrows button{
    pointer-events: none;
}


.slider.prev .list .item:nth-child(2) .content .title,
.slider.prev .list .item:nth-child(2) .content .type,
.slider.prev .list .item:nth-child(2) .content .description,
.slider.prev .list .item:nth-child(2) .content .buttons
{
    animation: contentOut 1.5s linear 1 forwards!important;
}

@keyframes contentOut{
    to{
        transform: translateY(-150px);
        filter: blur(20px);
        opacity: 0;
    }
}
@media screen and (max-width: 678px) {
    .slider .list .item .content{
        padding-right: 0;
    }
    .slider .list .item .content .title{
        font-size: 30px;
    }
}
