/* Fullscreen slider container */
.fullscreen-slider {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
}

/* Image container */
.imgbox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Image styling */
.fullscreen-image {
  width: 85vw; /* 80% of the viewport width */
  height: 92vh; /* 80% of the viewport height */
  object-fit: cover ; /* Ensures the image maintains its aspect ratio */
  display: block;
  border: none;
  /* background-color: black; */
}

/* Logo styling */
.logo {
  position: absolute;
  top: 20px;
  left: 100px;
  height: auto;
  width: 100vw; /* Make logo width 10% of viewport width */
  max-width: 250px; /* Set a maximum width for the logo */
  min-width: 50px; /* Set a minimum width for the logo */
  cursor: pointer;
}

/* Close button styling */
.close-button {
  position: absolute;
  top: 5px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 3rem;
  color: white;
  cursor: pointer;
  
}

/* Navigation buttons styling */
.navigation {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  
}

.prev-button,
.next-button {
  border: none;
  font-size: 2rem;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  color: white;
  margin: 0 20px;
  transition: background-color 0.3s ease;
}



/* Media queries for smaller screens */
@media (max-width: 768px) {
  .logo {
    width: 15vw; /* Increase width to 15% of the viewport on smaller screens */
    max-width: 80px; /* Reduce maximum width */
    min-width: 40px; /* Reduce minimum width */
    margin-left: -50px;
  }
}

@media (max-width: 480px) {
  .logo {
    width: 20vw; /* Increase width to 20% of the viewport on very small screens */
    max-width: 60px; /* Further reduce maximum width */
    min-width: 30px; /* Further reduce minimum width */
    margin-left: -50px;
  }
}

.music-toggle {
  position: absolute;
  top: 32px;
  right: 70px; /* Position it next to the close button */
  cursor: pointer;
  z-index: 10;
  width: 25px;
}

.fullscreen-toggle {
  position: absolute;
  top: 29px;
  right: 120px; /* Adjusted to be next to the close button */
  cursor: pointer;
  z-index: 10;
}