@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.container {
  position: relative;
  min-width: 100%;
  min-height: 100vh;
  padding: 2rem;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
}

.form {
  width: 100%;
  max-width: 820px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.contact-form {
  background-color: #ff7f50;
  position: relative;
}

.circle {
  border-radius: 50%;
  background: linear-gradient(135deg, transparent 20%, #e96e3b);
  position: absolute;
}

.circle.one {
  width: 130px;
  height: 130px;
  top: 130px;
  right: -40px;
}

.circle.two {
  width: 80px;
  height: 80px;
  top: 10px;
  right: 30px;
}

.contact-form:before {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  background-color: #ff7f50;
  transform: rotate(45deg);
  top: 50px;
  left: -13px;
}

form {
  padding: 2.3rem 2.2rem;
  z-index: 10;
  overflow: hidden;
  position: relative;
}

.title {
  color: #fff;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 0.7rem;
}

.input-container {
  position: relative;
  margin: 1rem 0;
}

.input {
  width: 100%;
  outline: none;
  border: 2px solid #fff;
  background: none;
  padding: 0.6rem 1.2rem;
  color: #fff;
  font-weight: 500;
  font-size: 0.95rem;
  letter-spacing: 0.5px;
  border-radius: 25px;
  transition: 0.3s;
}

textarea.input {
  padding: 0.8rem 1.2rem;
  min-height: 150px;
  border-radius: 22px;
  resize: none;
  overflow-y: auto;
}

.input-container label {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  padding: 0 0.4rem;
  color: #fff;
  font-size: 0.9rem;
  font-weight: 400;
  pointer-events: none;
  z-index: 1000;
  transition: 0.5s;
}

.input-container.textarea label {
  top: 1rem;
  transform: translateY(0);
}

.btn {
  padding: 0.6rem 1.3rem;
  background-color: #fff;
  border: 2px solid #fff;
  font-size: 0.95rem;
  color: #ff7f50;
  line-height: 1;
  border-radius: 25px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  margin: 0;
}

.btn:hover {
  background-color: transparent;
  color: black;
}

.input-container span {
  position: absolute;
  top: 0;
  left: 25px;
  transform: translateY(-50%);
  font-size: 0.8rem;
  padding: 0 0.4rem;
  color: transparent;
  pointer-events: none;
  z-index: 500;
}

.input-container span:before,
.input-container span:after {
  content: "";
  position: absolute;
  width: 10%;
  opacity: 0;
  transition: 0.3s;
  height: 5px;
  background-color: #ff7f50;
  top: 50%;
  transform: translateY(-50%);
}

.input-container span:before {
  left: 50%;
}

.input-container span:after {
  right: 50%;
}

.input-container.focus label {
  top: 0;
  transform: translateY(-50%);
  left: 25px;
  font-size: 0.8rem;
}

.input-container.focus span:before,
.input-container.focus span:after {
  width: 50%;
  opacity: 1;
}

.contact-info {
  padding: 2.3rem 2.2rem;
  position: relative;
}

.contact-info .title {
  color: #ff7f50;
}

.text {
  color: #333;
  margin: 1.5rem 0 2rem 0;
}

.information {
  display: flex;
  color: #555;
  margin: 0.7rem 0;
  align-items: center;
  font-size: 0.95rem;
}

.icon {
  width: 28px;
  margin-right: 0.7rem;
}

.social-media {
  padding: 2rem 0 0 0;
}

.social-media p {
  color: #333;
}

.social-icons {
  display: flex;
  margin-top: 0.5rem;
}

.social-icons a {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background: linear-gradient(45deg, #ff7f50, #e96e3b);
  color: #fff;
  text-align: center;
  line-height: 35px;
  margin-right: 0.5rem;
  transition: 0.3s;
}

.social-icons a:hover {
  transform: scale(1.05);
}

.contact-info:before {
  content: "";
  position: absolute;
  width: 110px;
  height: 100px;
  border: 22px solid #ff7f50;
  border-radius: 50%;
  bottom: -77px;
  right: 50px;
  opacity: 0.3;
}

.big-circle {
  position: absolute;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background: linear-gradient(to bottom, #ffa07a, #e96e3b);
  bottom: 50%;
  right: 50%;
  transform: translate(-40%, 38%);
}

.big-circle:after {
  content: "";
  position: absolute;
  width: 360px;
  height: 360px;
  background-color: #fff;
  border-radius: 50%;
  top: calc(50% - 180px);
  left: calc(50% - 180px);
}

.square {
  position: absolute;
  height: 400px;
  top: 50%;
  left: 50%;
  transform: translate(181%, 11%);
  opacity: 0.2;
}

@media (max-width: 850px) {
  .form {
    grid-template-columns: 1fr;
  }

  .contact-info:before {
    bottom: initial;
    top: -75px;
    right: 65px;
    transform: scale(0.95);
  }

  .contact-form:before {
    top: -13px;
    left: initial;
    right: 70px;
  }

  .square {
    transform: translate(140%, 43%);
    height: 350px;
  }

  .big-circle {
    bottom: 75%;
    transform: scale(0.9) translate(-40%, 30%);
    right: 50%;
  }

  .text {
    margin: 1rem 0 1.5rem 0;
  }

  .social-media {
    padding: 1.5rem 0 0 0;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 1.5rem;
  }

  .contact-info:before {
    display: none;
  }

  .square,
  .big-circle {
    display: none;
  }

  form,
  .contact-info {
    padding: 1.7rem 1.6rem;
  }

  .text,
  .information,
  .social-media p {
    font-size: 0.8rem;
  }

  .title {
    font-size: 1.15rem;
  }

  .social-icons a {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  .icon {
    width: 23px;
  }

  .input {
    padding: 0.45rem 1.2rem;
  }

  .btn {
    padding: 0.45rem 1.2rem;
  }
}

.input-container {
    position: relative;
    margin: 1rem 0;
  }
  
  .input-container label {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    padding: 0 0.4rem;
    color: #333;
    font-size: 0.9rem;
    font-weight: 400;
    pointer-events: none;
    z-index: 1000;
    transition: 0.3s ease-in-out;
  }
  
  .input-container.focus label,
  .input:focus + label {
    top: -10px;
    left: 24px;
    color: white; /* Change color on focus */
    font-size: 0.8rem;
    transform: translateY(0);
  }
  
  .input {
    width: 100%;
    outline: none;
    border: 2px solid white;
    background: none;
    padding: 0.6rem 1.2rem;
    color: #333;
    font-size: 0.95rem;
    border-radius: 25px;
    transition: 0.3s;
  }
  
  .input:focus {
    border-color: white; /* Change border color on focus */
  }
  
  .textarea {
    min-height: 120px;
  }
  


  /* Envlop */

  .wrapper {
    height: 150px; /* Smaller height */
    width: 250px; /* Smaller width */
    background-color: #FFA726; /* Orange */
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 0;
  }
  
  .lid {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border-right: 125px solid transparent;
    border-bottom: 75px solid transparent;
    border-left: 125px solid transparent;
    transform-origin: top;
    transition: transform 0.25s linear;
  }
  
  /* Lid when closed */
  .lid.one {
    border-top: 75px solid #FFCC80; /* Light orange */
    transform: rotateX(0deg);
    z-index: 3;
    transition-delay: 0.75s;
  }
  
  /* Lid when opened */
  .lid.two {
    border-top: 75px solid #FFA726; /* Orange */
    transform: rotateX(90deg);
    z-index: 1;
    transition-delay: 0.5s;
  }
  
  .envelope {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0;
    bottom: 0;
    border-top: 75px solid transparent;
    border-right: 125px solid #FFE0B2; /* Lighter orange */
    border-bottom: 75px solid #FFE0B2; /* Lighter orange */
    border-left: 125px solid #FFB74D; /* Medium orange */
    z-index: 3;
  }
  
  .letter {
    position: absolute;
    top: 0;
    width: 70%; /* Adjusted size */
    height: 70%; /* Adjusted size */
    background-color: white;
    border-radius: 10px;
    z-index: 2;
    transition: 0.5s;
  }
  
  .letter p {
    text-align: center;
    font-size: 20px; /* Smaller font size */
    margin-top: 20px;
    color: #3B4049; /* Neutral gray */
  }
  
  .wrapper:hover .lid.one {
    transform: rotateX(90deg);
    transition-delay: 0s;
  }
  
  .wrapper:hover .lid.two {
    transform: rotateX(180deg);
    transition-delay: 0.25s;
  }
  
  .wrapper:hover .letter {
    transform: translateY(-40px); /* Smaller offset */
    transition-delay: 0.5s;
  }
 
  .custom-orange-popup {
    background-color: #ff8c00; /* Orange background */
    color: #fff; /* White text */
  }
  
  .custom-orange-title {
    color: #fff; 
  }
  
  .custom-orange-button {
    background-color: white; 
    color: #fff; 
  }
  
  .custom-orange-button:hover {
    background-color: #444; /* Darker shade for hover */
  }
  

  /* lgo192.png */
  /* logo512.png */