/* Gallery.css */

/* General container styling */
.gallery-container {
  text-align: center;
  padding: 20px;
}

h1 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

/* Grid layout for the images */
.images-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
  padding: 10px;
}

.image-wrapper {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 8px;
  transition: transform 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* Set a fixed height for the grid items */
}

.image-wrapper:hover {
  transform: scale(1.05);
}

.image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the images fill the box without distortion */
  border-radius: 8px;
}

/* Lightbox styling */
.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.lightbox-content {
  width: 80%; /* Ensures the lightbox image is centered */
  max-width: 1000px; /* Max width to avoid overly large images */
  height: auto;
}

.lightbox-content img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: contain; /* Ensure the lightbox image fits within the box while maintaining its aspect ratio */
}

/* Loader styling */
.image-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 14px;
  color: #666;
  background: #f2f2f2;
  border-radius: 8px;
}

/* Maintain the rest of the styling */
.image-wrapper {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 8px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}
